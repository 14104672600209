import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import i18n from "../../i18n";
import DashboardCard from "../../components/DashboardCard";
import FormatHelpers from "../../services/FormatHelper";
import { useCreateConsultContext } from "./CreateConsultContext";
import ConsultPlan from "../../services/ConsultPlans/ConsultPlan";
import TimeSpan from "../../services/Core/Dates/TimeSpan";

export default function SelectPlan() {
  const { t } = useTranslation();
  const { organization, setPlan } = useCreateConsultContext();

  const getExpirationDays = (plan: ConsultPlan) => {
    console.log(plan.expiration);
    if (plan?.expiration) {
      var timespan = new TimeSpan(plan.expiration);
      return Math.floor(timespan.getDays());
    }
    return 0;
  };

  return (
    <Container sx={{ marginTop: 5 }}>
      <Typography variant="h1" mb={1} textAlign="center">
        {t("create-consult.title")}
      </Typography>
      <Typography color="text.secondary" mb={4} textAlign="center">
        {t("create-consult.subtitle")}
      </Typography>

      <Stack marginLeft={{ md: 5, lg: 25 }} marginRight={{ md: 5, lg: 25 }}>
        {organization!.plans.map((plan) => {
          const title = plan.title[i18n.language] ?? "";
          const description = plan.description[i18n.language] ?? "";
          const text = plan.text[i18n.language] ?? null;

          return (
            <Box key={plan.id} mb={4}>
              <DashboardCard title={title}>
                <Box mb={3}>{parse(description)}</Box>
                {text && <Box mb={1}>{text}</Box>}
                <Box mb={3}>
                  <Typography>
                    {t("common.price")}: {FormatHelpers.formatMoney(plan.price, i18n.language)}
                  </Typography>
                </Box>

                <Button onClick={() => setPlan(plan)}>{t("create-consult.start-consult")}</Button>
              </DashboardCard>
            </Box>
          );
        })}
      </Stack>
    </Container>
  );
}
