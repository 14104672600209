import Field from "./Field";

export default class FieldHelpers {
  public static getPropertyNumber(field: Field, name: string, defaultValue: number) {
    let value = parseInt(field.properties[name]);
    return isNaN(value) ? defaultValue : value;
  }

  public static showField(field: Field, data: any) {
    if (field.condition) {
      var dataValue = data[field.condition.field];

      if (field.condition.operator === "equal") {
        return dataValue === field.condition.value;
      } else if (field.condition.operator === "hasValue") {
        const hasValue = data.hasOwnProperty(field.condition.field) && dataValue !== undefined;
        //console.log("hasValue", field.condition, hasValue, data);
        return hasValue;
      } else {
        console.log(`Condition '${field.condition.operator}' unsupported.`);
      }
    }

    return true;
  }
}
