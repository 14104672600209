import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import i18n from "../../i18n";
import { FieldType } from "../../services/Entities/FieldType";
import DynamicFieldText from "./DynamicFieldText";
import DynamicFieldNumber from "./DynamicFieldNumber";
import DynamicFieldYesNo from "./DynamicFieldYesNo";
import DynamicFieldImageList from "./DynamicFieldImageList";
import PropsDynamicField from "./PropsDynamicField";
import dayjs, { Dayjs } from "dayjs";
import DynamicFieldLabel from "./DynamicFieldLabel";

export function DynamicTextField({ children }: React.PropsWithChildren<any>) {
  return <Typography mb={1}>{children}</Typography>;
}

export default function DynamicField(props: PropsDynamicField) {
  const { t } = useTranslation();

  const { organizationId, field, data, update } = props;

  const label = field.label[i18n.language] ?? "";
  const fullWidth = field.properties["fullWidth"] === "1";
  const hideLabel = field.properties["hideLabel"];

  return (
    <Grid item sm={fullWidth ? 12 : 6} md={fullWidth ? 12 : 6} lg={fullWidth ? 12 : 6} mb={3}>
      {hideLabel !== "1" && field.type !== FieldType.Label && <DynamicTextField>{label}</DynamicTextField>}

      {field.type === FieldType.Label && (
        <DynamicFieldLabel organizationId={organizationId} data={data} update={update} field={field} validated={props.validated} error={props.error} />
      )}

      {field.type === FieldType.Text && (
        <DynamicFieldText organizationId={organizationId} data={data} update={update} field={field} validated={props.validated} error={props.error} />
      )}

      {field.type === FieldType.Number && (
        <DynamicFieldNumber organizationId={organizationId} data={data} update={update} field={field} validated={props.validated} error={props.error} />
      )}

      {field.type === FieldType.YesNo && (
        <DynamicFieldYesNo organizationId={organizationId} data={data} update={update} field={field} validated={props.validated} error={props.error} />
      )}

      {field.type === FieldType.Date && (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker value={getDatePickerObject(data[field.id])} onChange={(date) => update(field.id, date?.format("MM/DD/YYYY"))} disableFuture />
          </LocalizationProvider>
          {props.validated && props.error && <Typography color="#f00">{t("questionnaire.ErrorRequired")}</Typography>}
        </>
      )}

      {field.type === FieldType.ImageList && (
        <DynamicFieldImageList organizationId={organizationId} field={field} data={data} validated={props.validated} error={props.error} update={update} />
      )}
    </Grid>
  );

  function getDatePickerObject(value: any): Dayjs | undefined {
    let date = new Date(value);
    //console.log(date);
    if (date != null) {
      return dayjs(date);
    }
    return undefined;
  }
}
